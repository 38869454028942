<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.contractListSearch')"
    :color="'next'"
  >
    <v-container class="pt-0 px-8">
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <v-col>
            <v-chip
              ripple
              class="font-weight-black float-right"
              @click="onClickClearButton()"
              >{{ $t('button.clear') }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-col>
        </v-row>
        <!-- 種目 -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-select
              v-model="condition.insuranceTypeId"
              :items="insuranceNames"
              :label="$t('label.insuranceName')"
              chips
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="insuranceNamesToggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        condition.insuranceTypeId.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ insuranceNamesIcon }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('button.allInsuranceType')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- 契約者番号 -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.policyNumber"
              :label="$t('label.policyNumber')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 前契約証券番号 -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.previousPolicyNumber"
              :label="$t('label.previousPolicyNumber')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 印刷連番 -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
                v-model="condition.printingSerialNumber"
                :label="$t('label.printingSerialNumber')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 契約者名（漢字） -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.fullName"
              :label="$t('label.fullName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 契約者名（カナ） -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.fullNameKana"
              :label="$t('label.fullNameKana')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 保険始期 -->
        <v-row>
          <v-col :class="conditionStyle">
            <p class="text--secondary mb-0">{{ $t('label.startDate') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle" class="me-1 pe-1">
            <v-menu :close-on-content-click="false" v-model="fromDateMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="condition.startDateFrom"
                  v-bind="attrs"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  :label="$t('label.startDateFrom')"
                />
              </template>

              <v-date-picker
                locale="jp-ja"
                :day-format="date => new Date(date).getDate()"
                v-model="condition.startDateFrom"
                no-title
                reactive
                @change="fromDateMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col :class="conditionStyle" class="ms-1 ps-1">
            <v-menu :close-on-content-click="false" v-model="toDateMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="condition.startDateTo"
                  v-bind="attrs"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  :label="$t('label.startDateTo')"
                />
              </template>

              <v-date-picker
                locale="jp-ja"
                :day-format="date => new Date(date).getDate()"
                v-model="condition.startDateTo"
                no-title
                reactive
                @change="toDateMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <!-- 代理店担当者コード -->
        <!-- <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.chargePersonCode"
              :label="$t('label.chargePersonCode')"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <!-- ステータス -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-select
              :items="statuses"
              v-model="condition.status"
              :label="$t('label.status')"
              chips
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="statusToggle">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        condition.status.length > 0 ? 'indigo darken-4' : ''
                      "
                      >{{ statusIcon }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t('button.allStatus')
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- メモ -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-textarea
              v-model="condition.note"
              :label="$t('label.note')"
              :counter="maxLength.ContractNote"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- 代理店コード（契約） -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.contractAgencyCode"
              :label="$t('label.contractAgencyCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 代理店コード（ユーザー） -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyAgencyCode"
              :label="$t('label.agencyAgencyCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 部担コード -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesCode"
              :label="$t('label.salesCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 担当者名 -->
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.staffName"
              :label="$t('label.staffName')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-btn width="33%" class="next" dark @click="onClickSearchButton()">{{
        $t('button.search')
      }}</v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import moment from 'moment';
import { ContractStatusDisplay, MaxLength } from '@/lib/const';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // 種目の選択肢
      insuranceNames: [],

      // ステータスの選択肢
      statuses: Object.keys(ContractStatusDisplay).map(key => ({
        text: ContractStatusDisplay[key],
        value: key,
      })),

      // 検索条件
      condition: {
        insuranceTypeId: '',
        policyNumber: '',
        previousPolicyNumber: '',
        printingSerialNumber: '',
        fullName: '',
        fullNameKana: '',
        startDateFrom: '',
        startDateTo: '',
        note: '',
        salesCode: '',
        contractAgencyCode: '',
        agencyAgencyCode: '',
        staffName: '',
        chargePersonCode: '',
        status: '',
      },

      // 検索フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0'],

      maxLength: MaxLength,

      fromDateMenu: false,
      toDateMenu: false,
    };
  },
  computed: {
    allInsuranceName() {
      return (
        this.condition.insuranceTypeId.length === this.insuranceNames.length
      );
    },
    someInsuranceName() {
      return (
        this.condition.insuranceTypeId.length > 0 && !this.allInsuranceName
      );
    },
    allStatus() {
      return this.condition.status.length === this.statuses.length;
    },
    someStatus() {
      return this.condition.status.length > 0 && !this.allStatus;
    },
    insuranceNamesIcon() {
      if (this.allInsuranceName) return 'mdi-checkbox-marked';
      if (this.someInsuranceName) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    statusIcon() {
      if (this.allStatus) return 'mdi-checkbox-marked';
      if (this.someStatus) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    insuranceNamesToggle() {
      this.$nextTick(() => {
        if (this.allInsuranceName) {
          this.condition.insuranceTypeId = [];
        } else {
          this.condition.insuranceTypeId = this.insuranceNames.map(
            array => array.value
          );
        }
      });
    },
    statusToggle() {
      this.$nextTick(() => {
        if (this.allStatus) {
          this.condition.status = [];
        } else {
          this.condition.status = this.statuses.map(array => array.value);
        }
      });
    },
    open(insuranceTypes) {
      this.$refs.dialog.open();
      this.insuranceNames = insuranceTypes.map(insuranceType => ({
        text: insuranceType.name,
        value: insuranceType.id,
      }));
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      this.condition.insuranceTypeId = '';
      this.condition.policyNumber = '';
      this.condition.previousPolicyNumber = '';
      this.condition.printingSerialNumber = '';
      this.condition.fullName = '';
      this.condition.fullNameKana = '';
      this.condition.startDateFrom = '';
      this.condition.startDateTo = '';
      this.condition.note = '';
      this.condition.salesCode = '';
      this.condition.contractAgencyCode = '';
      this.condition.agencyAgencyCode = '';
      this.condition.staffName = '';
      this.condition.chargePersonCode = '';
      this.condition.status = '';
    },
    onClickClearButton() {
      this.init();
      this.$emit('onClear');
    },
    onClickSearchButton() {
      // TODO: バリデーションの実施

      // 検索条件の指定
      const condition = {
        ...(this.condition.insuranceTypeId && {
          insuranceTypeId: this.condition.insuranceTypeId,
        }),
        ...(this.condition.policyNumber && {
          policyNumber: this.condition.policyNumber,
        }),
        ...(this.condition.previousPolicyNumber && {
          previousPolicyNumber: this.condition.previousPolicyNumber,
        }),
        ...(this.condition.printingSerialNumber && {
          printingSerialNumber: this.condition.printingSerialNumber,
        }),
        ...(this.condition.fullName && {
          fullName: this.condition.fullName,
        }),
        ...(this.condition.fullNameKana && {
          fullNameKana: this.condition.fullNameKana,
        }),
        ...(this.condition.startDateFrom && {
          startDateFrom: moment(this.condition.startDateFrom)
            .startOf('day')
            .format(),
        }),
        ...(this.condition.startDateTo && {
          startDateTo: moment(this.condition.startDateTo)
            .endOf('day')
            .format(),
        }),
        ...(this.condition.note && {
          note: this.condition.note,
        }),
        ...(this.condition.salesCode && {
          salesCode: this.condition.salesCode,
        }),
        ...(this.condition.contractAgencyCode && {
          contractAgencyCode: this.condition.contractAgencyCode,
        }),
        ...(this.condition.agencyAgencyCode && {
          agencyAgencyCode: this.condition.agencyAgencyCode,
        }),
        ...(this.condition.staffName && {
          staffName: this.condition.staffName,
        }),
        ...(this.condition.chargePersonCode && {
          chargePersonCode: this.condition.chargePersonCode,
        }),
        ...(this.condition.status && {
          status: this.condition.status,
        }),
      };

      this.$emit('onSuccess', condition);
    },
    onErrorSearch() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.contractListSearchResult'),
        this.$t('error.contractListNotFound')
      );
    },
  },
};
</script>
